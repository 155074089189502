<template>
  <b-card>
    <h3 class="mb-50 fw-700">
      Xem số vé, vé rút gọn 1S cũ
    </h3>
    <p class="text-warning font-italic ml-50">
      Nhập vào Booking code, mã đặt chỗ, pnr để xem vé rút gọn hoặc nhập vào số vé để xem thông tin chi tiết số vé.
    </p>
    <b-form ref="form">
      <b-row class="mx-0">
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Nhập vào PNR, số vé"
            label-for="pnr"
          >
            <b-form-input
              id="pnr"
              v-model="pnrOrTicketNum"
              :formatter="trimUpperCaseInput"
              placeholder="Nhập PNR..."
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
          class="text-body"
        >
          <b-form-group
            label="Chọn Hệ thống đặt vé"
            label-for="ticket-source"
          >
            <v-select
              v-model="ticketSource"
              input-id="ticket-source"
              :options="sourceOptions"
              :reduce="val => val.value"
              label="name"
              placeholder="Vui lòng chọn"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <div class="d-flex-center gap-2">
      <b-button
        variant="gradient"
        pill
        class="px-3"
        :disabled="disableSearch"
        @click="handleSearch"
      >
        {{ $t('reservation.find') }}
      </b-button>
    </div>

    <ModalShowText
      :text="shortenText"
      :title-modal="titleModal"
    />
  </b-card>
</template>

<script>
import {
  BButton,
  BFormInput,
  BFormGroup,
  BForm,
  BCard,
  BCol,
  BRow,
} from 'bootstrap-vue'
import {
  ref, computed,
} from '@vue/composition-api'
import vSelect from 'vue-select'

import { apiReservation } from '@/api'

import { trimUpperCaseInput } from '@core/comp-functions/forms/formatter-input'

import ModalShowText from './ModalShowText.vue'

export default {
  components: {
    BCard,
    BButton,
    BFormInput,
    BFormGroup,
    BForm,
    BCol,
    BRow,

    vSelect,
    ModalShowText,
  },

  setup() {
    const sourceOptions = ref([
      {
        name: 'Vietnam Airlines (1S)',
        value: 'VN1A',
      },
      {
        name: 'VNA Sabre Miền Trung (1S_CTRL)',
        value: 'VN1A_MT',
      },
    ])
    const pnrOrTicketNum = ref('')
    const ticketSource = ref('VN1A')
    const disableSearch = computed(() => !(pnrOrTicketNum.value && ticketSource.value))
    const isTicketNum = computed(() => pnrOrTicketNum.value?.length > 6)
    const shortenText = ref(null)
    const titleModal = ref('')

    async function handleSearch() {
      titleModal.value = ''
      try {
        this.$bvModal.show('modal-api-loading')
        const payload = {
          source: ticketSource.value,
        }

        if (isTicketNum.value) {
          payload.ticket = {
            type: pnrOrTicketNum.value.startsWith('73824') ? 'TKT' : 'EMD',
            number: pnrOrTicketNum.value,
          }

          const resTicketNum = await apiReservation.outSideTicket(payload)
          shortenText.value = resTicketNum?.ticketText
          titleModal.value = 'Xem số vé'
        } else {
          payload.pnrNumber = pnrOrTicketNum.value

          const resShortenBooking = await apiReservation.outSideShortenBooking(payload)
          shortenText.value = resShortenBooking?.booking?.join('\n') || 'NO_MATCHING_RECORD'
          titleModal.value = 'Xem vé rút gọn'
        }

        this.$bvModal.show('modal-reservation-show-text')
      } catch (error) {
        console.log(error)
      } finally {
        this.$bvModal.hide('modal-api-loading')
      }
    }

    return {
      titleModal,
      pnrOrTicketNum,
      ticketSource,
      disableSearch,
      trimUpperCaseInput,
      handleSearch,
      sourceOptions,
      shortenText,
    }
  },
}
</script>
